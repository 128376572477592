@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @import url("../fonts/verdana/css/index.css");
    @import url("../fonts/arial/css/index.css");
    @import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

    body {
        color: #000;
        font-size: 16px;
        font-family: "Arial", sans-serif;
    }
}

@layer utilities {
    .font-tiny {
        font-size: 10px;
    }

    .font-verdana {
        font-family: "Verdana", serif;
    }
    
    .font-noto {
        font-family: "Noto Serif", serif;
    }
    
    .font-arial-light {
        font-family: "ArialLight";
    }
    
    .font-short,
    .font-shorter {
        transform: scale(1, .9);
        -webkit-transform: scale(1, .9);
    }
    
    .form-input {
        @apply ring-vetiva-brown focus:ring-vetiva-brown;
    }

    .form-group-icon:focus-within {
        @apply ring-vetiva-brown;
    }
    
    .header-top-links{
        @apply px-6 hover:text-vetiva-brown;
    }

    .header-top-divider{
        @apply w-px bg-gray-400;
    }

    .header-links-mobile{
        @apply mx-auto py-4 text-center;
    }

    .header-links-mobile > a{
        @apply px-4 py-2 inline-block font-medium text-xl hover:text-vetiva-brown;
    }

    .header-links-social{
        @apply w-10 h-10 p-2 text-center flex hover:bg-vetiva-brown;
    }

    .header-links-social > img{
        @apply w-full h-full object-contain;
    }

    .header-links a{
        @apply text-sm px-4 hover:bg-gray-700 rounded;
    }

    .header-links a > div{
        @apply py-1;
    }

    .header-links a.active > div{
        @apply text-vetiva-brown border-b-[3px] border-vetiva-brown;
    }

    .btn-gold,
    .btn-vetiva-gold{
        @apply text-vetiva-brown bg-vetiva-gold;
        @apply ring-vetiva-gold;
    }
    
    .btn-brown,
    .btn-vetiva-brown{
        @apply text-white bg-vetiva-brown;
        @apply ring-vetiva-brown;
    }
    
    .btn-black,
    .btn-vetiva-black{
        @apply text-white bg-vetiva-black;
        @apply ring-vetiva-black;
    }

    .form-checkbox,
    .form-input-checkbox {
        @apply text-vetiva-brown border-vetiva-brown ring-vetiva-brown rounded !important;
    }

    .form-radio,
    .form-input-radio {
        @apply text-vetiva-brown border-vetiva-brown ring-vetiva-brown !important;
    }

    .tooltip-vetiva{
        @apply text-black bg-white opacity-100 shadow-md !important;
    }

    .bg-auth-pattern{
        background-image: url("../images/bgs/pattern_fullgap_deep.png");
        background-position: top center;
        @apply bg-cover bg-no-repeat;
    }

    .bg-vetiva-pattern{
        background-image: url("/src/assets/images/bgs/pattern_fullgap_deep.png");
        background-position: center;
        background-size: 400px;
    }

    .bg-vetiva-pattern-2{
        background-image: url("/src/assets/images/bgs/pattern-brown.png");
        background-position: center;
        background-size: 400px;
    }

    .bg-vetiva-palm{
        background-image: url("/src/assets/images/bgs/Frame.svg");
        background-position: bottom right;
        background-repeat: no-repeat;
    }
}
