
/* 
@font-face {
  font-family: 'Verdana';
  src: url('../fonts/Verdana-Extralight.woff2') format('woff2'),
       url('../fonts/Verdana-Extralight.woff') format('woff'),
       url('../fonts/Verdana-Extralight.ttf') format('truetype');
       font-weight: 200;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Verdana';
  src: url('../fonts/Verdana-Light.woff2') format('woff2'),
       url('../fonts/Verdana-Light.woff') format('woff'),
       url('../fonts/Verdana-Light.ttf') format('truetype');
       font-weight: 300;
       font-display: swap;
       font-style: normal;
}
*/

@font-face {
  font-family: 'Verdana';
  src: url('../fonts/Verdana-Regular.woff2') format('woff2'),
       url('../fonts/Verdana-Regular.woff') format('woff'),
       url('../fonts/Verdana-Regular.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: normal;
}

/*
@font-face {
  font-family: 'Verdana';
  src: url('../fonts/Verdana-Medium.woff2') format('woff2'),
       url('../fonts/Verdana-Medium.woff') format('woff'),
       url('../fonts/Verdana-Medium.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: normal;
}
*/


@font-face {
  font-family: 'Verdana';
  src: url('../fonts/Verdana-Semibold.woff2') format('woff2'),
       url('../fonts/Verdana-Semibold.woff') format('woff'),
       url('../fonts/Verdana-Semibold.ttf') format('truetype');
       font-weight: 600;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Verdana';
  src: url('../fonts/Verdana-Bold.woff2') format('woff2'),
      url('../fonts/Verdana-Bold.woff') format('woff'),
       url('../fonts/Verdana-Bold.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: normal;
}
