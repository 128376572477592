
/* 
@font-face {
  font-family: 'Arial';
  src: url('../fonts/arial-extralight.woff2') format('woff2'),
       url('../fonts/arial-extralight.woff') format('woff'),
       url('../fonts/arial-extralight.ttf') format('truetype');
       font-weight: 200;
       font-display: swap;
       font-style: normal;
}
*/


@font-face {
  font-family: 'ArialLight';
  src: url('../fonts/arial-light.woff2') format('woff2'),
       url('../fonts/arial-light.woff') format('woff'),
       url('../fonts/arial-light.ttf') format('truetype');
       font-weight: 300;
       font-display: swap;
       font-style: normal;
}


/* 
@font-face {
  font-family: 'Arial';
  src: url('../fonts/arial-regular.woff2') format('woff2'),
  url('../fonts/arial-regular.woff') format('woff'),
  url('../fonts/arial-regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Arial';
  src: url('../fonts/arial-medium.woff2') format('woff2'),
       url('../fonts/arial-medium.woff') format('woff'),
       url('../fonts/arial-medium.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Arial';
  src: url('../fonts/arial-semibold.woff2') format('woff2'),
       url('../fonts/arial-semibold.woff') format('woff'),
       url('../fonts/arial-semibold.ttf') format('truetype');
       font-weight: 600;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Arial';
  src: url('../fonts/arial-bold.woff2') format('woff2'),
      url('../fonts/arial-bold.woff') format('woff'),
       url('../fonts/arial-bold.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: normal;
}
*/
