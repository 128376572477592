@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        transition: background-color 5000s ease-in-out 0s;
    }
    
    input[class='form-input']:-webkit-autofill,
    input[class='form-input']:-webkit-autofill:hover,
    input[class='form-input']:-webkit-autofill:focus,
    textarea[class='form-input']:-webkit-autofill,
    textarea[class='form-input']:-webkit-autofill:hover,
    textarea[class='form-input']:-webkit-autofill:focus,
    select[class='form-input']:-webkit-autofill,
    select[class='form-input']:-webkit-autofill:hover,
    select[class='form-input']:-webkit-autofill:focus {
        @apply form-input;
    }

    input[class='form-input-icon']:-webkit-autofill,
    input[class='form-input-icon']:-webkit-autofill:hover,
    input[class='form-input-icon']:-webkit-autofill:focus,
    textarea[class='form-input-icon']:-webkit-autofill,
    textarea[class='form-input-icon']:-webkit-autofill:hover,
    textarea[class='form-input-icon']:-webkit-autofill:focus,
    select[class='form-input-icon']:-webkit-autofill,
    select[class='form-input-icon']:-webkit-autofill:hover,
    select[class='form-input-icon']:-webkit-autofill:focus {
        @apply form-input-icon;
    }
}

@layer utilities {
    body{
        @apply text-sm;
        /* min-width: 1580px; */
    }

    .form-group {
        @apply space-y-1;
    }

    .form-group label {
        @apply block text-sm;
    }

    .form-input-required {
        @apply ml-1 text-red-500;
    }

    .form-input {
        @apply w-full h-10 px-4 py-1 text-sm border focus:outline-none focus:ring-2 focus:border-transparent;
        @apply border-gray-300 rounded !important;
        font-size: 14px !important;
    }

    .form-input.disabled,
    .form-input[disabled] {
        @apply bg-gray-200 opacity-100;
    }

    .form-input-sm {
        @apply form-input;
        @apply h-7 text-sm !important;
    }

    .form-group-icon {
        @apply w-full text-sm bg-white rounded ring-2 ring-transparent;
        @apply flex overflow-hidden;
    }

    .form-group-icon:focus-within {
        @apply border-transparent outline-none;
    }

    .form-group-icon > label {
        @apply w-10 h-full flex flex-shrink-0;
    }

    .form-group-icon > label > .form-group-icon-attach{
        @apply m-auto;
    }

    .form-input-icon {
        @apply w-full h-9 px-0 py-1 text-sm border-none outline-none focus:border-transparent focus:outline-none focus:ring-0;
    }

    #nav-icon {
        @apply w-7 h-9 m-auto relative cursor-pointer;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
    }
    
    #nav-icon span {
        @apply w-full h-1;
        @apply block fixed left-0 opacity-100 rounded-md bg-black;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
    }
    
    #nav-icon.nav-icon-white span {
        @apply bg-white !important;
    }
    
    #nav-icon span:first-child {
        @apply top-2;
    }
    
    #nav-icon span:nth-child(2),
    #nav-icon span:nth-child(3) {
        @apply top-4;
    }
    
    #nav-icon span:nth-child(4) {
        @apply top-6;
    }
    
    #nav-icon.open span {
        --tw-bg-opacity: 1;
        background-color: rgba(66, 111, 244, var(--tw-bg-opacity));
    }
    
    #nav-icon.open span:first-child {
        @apply w-0 top-[18px] left-1/2;
    }
    
    #nav-icon.open span:nth-child(2) {
        transform: rotate(45deg);
    }
    
    #nav-icon.open span:nth-child(3) {
        transform: rotate(-45deg);
    }
    
    #nav-icon.open span:nth-child(4) {
        @apply w-0 top-[18px] left-1/2;
    }

    .text-fade{
        @apply text-gray-400;
    }

    .btn-default {
        @apply w-max font-medium;
        @apply flex items-center justify-center space-x-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-opacity-75 cursor-pointer;
    }

    .btn {
        @apply btn-default;
        @apply btn-md;
    }

    .btn,
    .btn * {
        @apply whitespace-nowrap;
    }

    .btn.disabled,
    .btn[disabled] {
        @apply cursor-default;
        opacity: 0.7;
        filter: opacity(70);
    }

    @media (min-width: 768px) {
        .btn-md-lg{
            @apply btn-lg;
        }
    }

    @media (max-width: 767px) {
        .btn-md-lg{
            @apply btn-md;
        }
    }

    .btn-sm {
        @apply h-7 px-4 text-xs rounded-sm;
    }

    .btn-md {
        @apply h-9 px-5 text-sm rounded-[4px];
    }

    .btn-lg {
        @apply h-12 px-6 text-sm rounded-[6px];
    }

    .btn-block {
        @apply w-full;
    }

    .btn-transparent-black {
        @apply border border-gray-400;
    }

    .form-checkbox,
    .form-input-checkbox {
        @apply rounded cursor-pointer !important;
    }

    .animate {
        @apply transform transition duration-500 ease-in-out;
    }

    .react-slideshow-container + ul.indicators .each-slideshow-indicator:before {
        background: #fff;
    }

    .object-cover,
    .object-contain{
        @apply w-full h-full;
    }

    .container{
        @apply mx-auto px-4 xl:px-10;
    }

    .dropdown {
        @apply z-40 bg-white absolute rounded shadow-lg overflow-hidden;
        min-width: 100px;
    }

    .dropdown-left {
        @apply left-0;
    }

    .dropdown-right {
        @apply right-0;
    }

    .dropdown-item {
        @apply px-4 py-2 text-sm font-normal block border-t border-gray-100 cursor-pointer hover:bg-gray-200;
    }

    .dropdown > .dropdown-item:nth-child(1) {
        @apply border-t-0;
    }
    
    .box-border-only{
        @apply bg-white rounded-md border;
        /* border-color: #9CA3AF; */
    }

    .box-default-overflow {
        @apply p-4 sm:p-6 box-border-only;
    }

    .box {
        @apply box-default-overflow overflow-hidden;
    }

    .box-no-padding {
        @apply p-0 xl:p-0;
    }
    
    .label {
        @apply px-3 py-1 rounded-sm whitespace-nowrap inline font-bold;
        font-size: 12px;
        text-transform: lowercase;
    }
    
    .label:first-letter,
    .label:first-line {
        text-transform: uppercase !important;
    }

    .label.label-default-case:first-letter,
    .label.label-default-case:first-line {
        text-transform: none !important;
    }

    .label-blue {
        @apply bg-blue-200;
    }

    .label-green {
        @apply text-[#15692A] bg-[#D4F7DC];
    }

    .label-red {
        @apply text-[#FC5057] bg-[#FFD4D2];
    }

    .label-gray {
        @apply bg-gray-200;
    }

    .label-yellow {
        @apply text-[#804C00] bg-[#FFEBCC];
    }

    .table-container {
        @apply w-full overflow-x-auto overflow-y-hidden;
    }

    .table-container > .table th,
    .table-container > .table td {
        /* @apply w-min whitespace-nowrap; */
        @apply w-min;
    }

    .table {
        @apply w-full min-w-min rounded-md overflow-hidden;
        border-collapse: separate;
        /* border-spacing: 0 12px; */
        border-spacing: 0 0;
    }

    .table thead {
        @apply border-b;
    }

    .table thead th {
        @apply p-4 text-sm text-left font-normal;
    }

    .table tbody {
        /* @apply bg-white; */
    }

    .table tbody tr {
        /* @apply border-t border-b border-black !important; */
    }
    
    .table tbody tr:not(.table-no-data):hover {
        @apply bg-gray-100;
    }
    
    .table tbody tr td {
        @apply p-4 text-sm text-left;
        @apply border-t border-gray-200;
    }
    
    .table.table-border tbody tr.table-row-space {
        @apply h-3 invisible !important;
    }

    .table.table-border tbody > tr > td {
        /* @apply border-t border-b border-gray-200 overflow-hidden !important; */
    }

    .table.table-rounded tbody > tr > td:first-child{
        /* @apply border-l rounded-tl-lg rounded-bl-lg; */
    }
    
    .table.table-rounded tbody > tr > td:last-child{
        /* @apply border-r rounded-tr-lg rounded-br-lg; */
    }

    .table.table-align-top td {
        @apply align-top;
    }

    .table td .table-dropdown-align-top {
        @apply -mt-2;
    }

    .table-info-full-page {
        @apply box;
        @apply py-24 mt-6;
    }

    .table-info {
        @apply px-2 py-10 mt-3 text-center text-gray-500;
    }

    .table-info > svg {
        @apply text-4xl mb-2;
    }

    .page-info-italic {
        @apply py-2 text-center italic text-gray-500;
    }

    .pagination {
        @apply flex rounded space-x-0.5 overflow-hidden;
    }

    .pagination-items {
        @apply h-8 px-4 text-xs flex space-x-2 rounded-md cursor-pointer hover:bg-gray-200;
        @apply mr-1 !important;
    }

    .pagination-items > div{
        @apply my-auto;
    }

    .pagination-items-icon {
        @apply w-4 h-3 m-auto;
    }

    .pagination-items.disabled {
        @apply bg-gray-100 cursor-default hover:bg-gray-100;
    }

    .pagination-items-icon.disabled,
    .pagination-items-icon.disabled > div {
        @apply text-gray-300 group-hover:text-gray-300;
    }

    .pagination-items-active{
        @apply text-white bg-blue-500 hover:bg-blue-500;
    }

    .tab-pill {
        @apply px-5 sm:px-10 py-1 m-auto text-sm rounded-lg hover:text-white hover:bg-blue-500;
    }

    .tab-pill-active {
        @apply bg-white shadow;
    }

    .ellipsis {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .ellipsis-2-lines,
    .ellipsis-two-lines,
    .ellipsis-line-2,
    .ellipsis-line-two,
    .ellipsis-lines-2,
    .ellipsis-lines-two {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .tab {
        @apply py-3 text-sm font-bold text-gray-500 border-b-2 hover:bg-gray-100;
    }
    
    .tab.active {
        @apply text-blue-500 bg-blue-100 border-blue-500;
    }

    .tab-design-border {
        @apply flex border border-gray-300 rounded-md overflow-hidden;
    }

    .tab-design-border > div {
        @apply px-3 py-1 text-sm border-r border-gray-300 font-bold cursor-pointer;
    }

    .tab-design-border > div:last-child {
        @apply border-r-0;
    }

    .tab-design-border > div:hover {
        @apply bg-gray-200;
    }

    .tab-design-border > div.active {
        @apply text-white bg-blue-500;
    }

    .tab-design-button {
        @apply btn btn-lg btn-block;
        @apply ring-0 !important;
    }

    .note {
        @apply text-sm;
        padding: 5px 10px;
        text-align: justify;
        background: #ffea77 !important;
    }

    .floated-content:after {
        display: block;
        content: "";
        clear: both;
    }

    #headlessui-menu-items-2{
        outline: none !important;
    }

    .bg-gray {
        background: #F9FAFB;
    }

    .bg-fade-blue,
    .bg-blue-fade {
        background: #D9E3FF;
    }

    .react-confirm-alert-overlay{
        /* background: rgba(255, 255, 255, 0.77); */
        background: rgba(0, 0, 0, 0.77);
    }

    .capitalize-first-letter:first-letter{
        @apply uppercase;
    }

    .bg-blur{
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);
        /* background-color: rgba(255, 255, 255, 0.5); */
    }

    .form-input-floating{
        @apply form-input;
        @apply block px-2.5 pb-2.5 pt-4  appearance-none;
        @apply cursor-text;
    }
    
    .form-label-floating{
        @apply absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 left-1;
        @apply peer-focus:px-2 peer-focus:text-vetiva-brown peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4;
        @apply w-full cursor-text bg-red-300 -mt-10;
    }
}
